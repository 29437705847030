<!-- TreeNode.vue -->
<template>
  <div class="tree-node" :style="{ '--level': level }">
    
    <div class="node-content" @click="clicked">
      <i v-if="node.type === 'riks'" class="fas fa-sitemap"></i>
      <i v-if="node.type === 'default'" class="fas fa-building"></i>
      <i v-if="node.type === 'student'" class="fas fa-book"></i>
      <i v-if="node.type === 'workgroup'" class="fas fa-user"></i>
      
      {{ node.name }} ({{ node.member_count || 0 }})
    </div>
    <div class="children" v-if="hasChildren">
      <CompanyTreeNode
        @on_click="clicked"
        @selected="selected"
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
        :level="level + 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyTreeNode',
  props: {
    node: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clicked() {
      this.$emit('selected', this.node);
    },
    selected(node) {
      this.$emit('selected', node);
    }
  },
  computed: {
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
  },
};
</script>

<style scoped>
.tree-node {
  position: relative;
  padding-left: 20px;
  --connector-color: #ccc;
}

.node-content {
  position: relative;
  padding: 5px 0;
}

.node-content:hover {
  cursor: pointer;
  background-color: #aaddff;
}

/* Horizontal line from parent to child */
.tree-node::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  width: 10px;
  height: 1px;
  background-color: var(--connector-color);
}

/* Vertical line connecting children */
.children {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
}

.children::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: var(--connector-color);
}
</style>
